.App {
  text-align: center;
  max-width: 1400px;
  margin: 0px auto;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.transparent {
  fill: transparent !important;
}

.color-1 {
  fill: #0a2d44 !important;
}

.color-2 {
  fill: #267dbf !important;
}

.image-filtered {
  /* To generate this filter: https://codepen.io/sosuke/pen/Pjoqqp */
  filter: brightness(0) saturate(100%) invert(44%) sepia(55%) saturate(646%) hue-rotate(163deg) brightness(88%) contrast(99%);
}

.companyImageContainer {
  display: inline-block;
  margin-right: 10px;
}

.companyImageContainer img {
  max-width: 350px;
}

.trackerImageContainer {
  display: inline-block;
  margin-bottom: 20px;
}

.serviceProfessionalLink {
  text-decoration: none;
  color: #267DBF;
  font-style: italic;
  display: block;
}

.serviceProfessionalLink:hover {
  cursor: pointer !important;
}

@media (max-width: 1400px) {}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.emp-container {
  text-align: center;
  margin-top: 20px;
}

.emp-container .emp-wrapper {
  background-color: #267DBF;
  color: #FFFFFF;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.emp-container .emp-heading {
  font-size: 3em;
  color: #FFFFFF;
  text-shadow: 0.25rem 0.75rem 1rem rgb(0 0 0 / 20%), -0.25rem 0.75rem 1rem rgb(0 0 0 / 20%);
  font-weight: 600;
  padding-top: 10px;
  padding-bottom: 8px;
}

.emp-container .flex {
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.emp-container .info {
  -ms-flex-pack: start;
  padding-top: 20px;
  justify-content: flex-start;
}

.emp-container .info img {
  width: 27%;
  background-size: cover;
  box-shadow: 0.25rem 0.75rem 1rem rgb(0 0 0 / 10%), -0.25rem 0.75rem 1rem rgb(0 0 0 / 10%);
}

.emp-container .title {
  margin-left: 8%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.emp-container .title>.name {
  color: #FFFFFF;
  font-size: 2rem;
  margin: .7rem 0;
}

.emp-container .title>.company {
  font-weight: 300;
  display: block;
  font-size: 1.5em;
  line-height: 1.3;
}

.emp-container .bio {
  display: block;
  border-bottom: 2px solid #FFFFFF;
  text-align: left;
  font-size: 1.1em;
  padding-bottom: 8px;
}

.emp-container .bio-last {
  display: block;
  text-align: left;
  font-size: 1.1em;
  padding-bottom: 8px;
}

.emp-container .thin {
  max-width: 45em;
  margin-right: auto;
  margin-left: auto;
}

.footer-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
}

.footer-buttons-card {
  background-color: #f3f4f6;
  padding: 22px;
}

.footer-button-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.footer-buttons-divider {
  width: 32px;
}

.footer-button {
  background-color: #507dc3;
  color: #FFFFFF;
  text-decoration: none;
  padding: 10px 20px;
  display: inline-flex;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 400;
  gap: 8px;
  margin: 8px;
  cursor: pointer;
}

.flex-middle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-link {
  color: #000000;
  text-decoration-color: blue;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}

@media (max-width: 1020px) {
  .footer-buttons {
    display: block;
    margin-top: 32px;
  }

  .footer-buttons-divider {
    margin-top: 32px;
  }

  .footer-button-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}